<template>
    <main class="main">
    <div class="background-left"></div>
    <div class="background-right"></div>
    <div class="container">
        <div class="hello">
            <h2 class="hello__title"><span>Здравствуйте!</span></h2>
            <div class="hello__text"><span>
                Вы находитесь на странице оплаты школы Кэнбу Кокоро. Пожалуйста, заполните таблицу ниже
                    и следуйте инструкциям платежной системы при оплате
            </span></div>
        </div>
        <div class="form">
            <form class="form__form" id="form" @submit.prevent="sendData">
                <div class="form__title">ФИО ученицы</div>
                <div class="form__subtitle">Введите полностью ФИО занимающегося</div>
                <input class="form__fio-input form_input" type="text" v-model="name" placeholder=" " required>
                <div class="form__contacts">
                    <div class="form__contacts-phone">
                        <div class="form__title">Телефон</div>
                        <input class="form__phone-input form_input" type="text" v-model="phone" placeholder=" " required >
                    </div>
                    <div class="form__contacts-email">
                        <div class="form__title">E-mail</div>
                        <input class="form__mail-input form_input" type="email" v-model="email" placeholder=" " required>
                        <span class="form__error">В формате: example@site.com</span>
                    </div>
                </div>
                <div class="form__type">
                    <div class="form__title"><span>Выберите для оплаты</span></div>
                    <select class="form__type-input form_input has_arrow" name="type" v-model="selected_type" required>
                        <option :value="null" disabled hidden>Тип услуги</option>
                        <option v-for="option in options" :value="option" v-bind:key="option.name">{{option.name}}</option>
                    </select>
                </div>
                <div class="form__group">
                    <div class="form__subtitle group-subtitle"><span>{{selected_type.description}}</span>
                    </div>
                    <select class="form__group-input form_input has_arrow" name="group" v-model="selected_product" required>
                        <option :value="null" disabled hidden>Выберете вашу группу</option>
                        <option v-for="product in filteredProducts" :value="product" v-bind:key="product.name">{{product.name}}</option>
                    </select>
                </div>
                <div class="form__pay" v-if="selected_product">
                    <div class="form__pay-lessons">
                        <div class="form__title">{{selected_type.price_tip}}</div>
                        <select class="form__lesson-input form_input has_arrow" name="lessons" v-model="selected_price" required>
                            <option
                                v-for="price in selected_product.prices"
                                :value="price"
                                v-bind:key="price.name"
                            >
                                {{price.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form__pay-count" v-if="selected_price.counter">
                        <div class="form__title">{{selected_type.counter_tip}}</div>
                        <div class="form__count-item form_input">
                            <button :disabled="selected_count <= 1" type="button" class="form__count-button" data-direction="minus" v-on:click="selected_count -= 1"><picture><source srcset="@/assets/img/arrow_left.webp" type="image/webp"><img src="@/assets/img/arrow_left.png" alt=""></picture></button>
                            <input class="form__count-input" type="text" v-model="selected_count" autocomplete="off">
                            <button type="button" class="form__count-button" data-direction="plus" v-on:click="selected_count += 1"><picture><source srcset="@/assets/img/arrow_right.webp" type="image/webp"><img src="@/assets/img/arrow_right.png" alt=""></picture></button>
                        </div>
                    </div>
                </div>
                <div class="form__month" v-if="selected_type.value === 'subscription'">
                    <div class="form__title">Оплата за месяц</div>
                    <select class="browser-default form__month-input form_input has_arrow" name="month" v-model="options.month" required>
                        <option
                            v-for="month in months"
                            :value="month.value"
                            v-bind:key="month.name"
                        >
                            {{month.name}}
                        </option>
                    </select>
                </div>
                <div class="form__sum">
                    Сумма к оплате:<span class="form_sum-value">{{totalCost}}р</span>
                </div>
                <div class="form__agreement">
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox" type="checkbox" name="personal_info" value="yes" required></label>
                        <span class="form__agreement-argument">Я даю свое согласие на обработку персональных данных</span>
                    </div>
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox " type="checkbox" name="offer_agreement" value="yes" required></label>
                        <span class="form__agreement-argument">Я принимаю <a href="#" target="_blank">договор-оферту</a></span>
                    </div>
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox" type="checkbox" name="privacy_policy" value="yes" required></label>
                        <span class="form__agreement-argument">Я ознакомлен с <a @click = "openPolicy()">Политикой конфиденциальности</a></span>
                        <modal-container />
                    </div>
                </div>
                <button class="form_button-pay" type="submit" name="paybtn" >Оплатить</button>
            </form>
        </div>
    </div>
    </main>
</template>

<script>
import axios from 'axios';
import {container, openModal} from "jenesius-vue-modal";
import Modal from './Modal.vue'

export default {
  components: {ModalContainer: container},
  name: 'Main',
    data: () => ({
        options: [
            {
              value: "subscription",
              name: "Оплата занятий",
              description: "Выберите из списка группу, в которой вы занимаетесь: по метро и началу занятий в будний день.",
              price_tip: "Выберите тип абонемента",
              counter_tip: "Количество разовых занятий"
            },
            {
              value: "service",
              name: "Семинары",
              description: "Выберите тип",
              price_tip: "Укажите кол-во, если требуется",
              counter_tip: "Укажите кол-во"
            },
            {
              value: "product",
              name: "Заказ формы",
              description: "Выберите наименование",
              price_tip: "Укажите кол-во, если требуется",
              counter_tip: "Укажите кол-во"
            },
        ],
        products: [],
        filteredProducts: [],
        months: [
            {value: 0, name:'Январь'},
            {value: 1, name:'Февраль'},
            {value: 2, name:'Март'},
            {value: 3, name:'Апрель'},
            {value: 4, name:'Май'},
            {value: 5, name:'Июнь'},
            {value: 6, name:'Июль'},
            {value: 7, name:'Август'},
            {value: 8, name:'Сентябрь'},
            {value: 9, name:'Октябрь'},
            {value: 10, name:'Ноябрь'},
            {value: 11, name:'Декабрь'}
        ],
        selected_type: {},
        selected_product: {},
        selected_price: {},
        selected_count: 1,
        name: "",
        email: "",
        phone: "",
    }),
    computed: {
      totalCost() {
        return this.selected_price.value * this.selected_count
      }
    },
    watch: {
      selected_type: function(value) {
          this.filteredProducts = this.products.filter((product) => product.type === value.value)
          this.selected_count = 1
          if (this.filteredProducts.length) {
            this.selected_product = this.filteredProducts[0]
            if (this.selected_product.prices.length) {
              this.selected_price = this.selected_product.prices[0]
            }
          } else {
            this.selected_price = {}
            this.selected_product = {}
          }
      },
      filteredProducts: function (value) {
        this.selected_count = 1
        if (value.length){
          this.selected_product = value[0]
        } else {
          this.selected_product = {}
        }
      },
      selected_product: function (value) {
        this.selected_count = 1
        if (value.prices !== undefined && value.prices.length){
          this.selected_price = value.prices[0]
        } else {
          this.selected_price = {
            value: 0
          }
        }
      },
      // eslint-disable-next-line no-unused-vars
      selected_price: function (value) {
        this.selected_count = 1
      }
    },
    mounted() {
      const headers = {
        "Authorization": "Token f756c047576aa281b3d3ed9306f8c0e02e4a99dd",
      };
      axios
        .get('https://payment-back.kenbu-kokoro.ru/api/product', {headers})
        .then(response => {
            this.products = response.data;
            this.selected_type = this.options[0]
            this.filteredProducts = this.products.filter((product) => product.type === this.selected_type.value)
            this.selected_product = this.filteredProducts[0]
            this.selected_price = this.selected_product.prices[0]
        })
        .catch(error => {
            console.log(error);
        })
      // this.options.month = (new Date().getMonth()+1)%12 // С 6ого числа
      // this.options.type = this.payment_types.find(o => o.selected === true).value;
    },
    methods: {
      openPolicy(){
        const props = {
          title: "Политика конфиденциальности",
          text: "ОГРНИП 317774600144626<br>" +
              "ИНН 772609763528<br>" +
              "ИП Кузнецова-Гуренкова Ю. А.<br>" +
              "01.01.2020 года<br>" +
              "Политика обработки персональных данных<br>" +
              "1. ВВЕДЕНИЕ<br>" +
              "1.1. Политика обработки персональных данных (далее — «Политика») издана и применяется ИП Кузнецова-Гуренкова Ю. А. (далее — «Оператор») в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».<br>" +
              "Настоящая Политика определяет порядок и условия Оператора в отношении обработки персональных данных, устанавливает положения, направленные на соблюдение законодательства Российской Федерации, касающееся обработки персональных данных.<br>" +
              "Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящей Политикой, разрешаются в соответствии с действующим законодательством Российской Федерации в области персональных данных.<br>" +
              "2. ОСНОВНЫЕ ПОЛОЖЕНИЯ<br>" +
              "2.1. Целью обработки персональных данных является: предоставление услуг Оператором по поручению субъекта персональных данных.<br>" +
              "2.2. Обработка организована Оператором на принципах:<br>" +
              "• законности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;<br>" +
              "• достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;<br>" +
              "• обработки только тех персональных данных, которые отвечают целям их обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;<br>" +
              "• соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки.<br>" +
              "• недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;<br>" +
              "• обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;<br>" +
              "• хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных;<br>" +
              "• обеспечения записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения персональных данных граждан РФ с использованием баз данных, находящихся на территории РФ.<br>" +
              "2.3. Оператор обрабатывает следующие персональные данные:<br>" +
              "• имя, фамилию субъекта персональных данных;<br>" +
              "• номер телефона субъекта персональных данных;<br>" +
              "• электронную почту субъекта персональных данных.<br>" +
              "2.4. Персональные данные, указанные выше обрабатываются с использованием средств автоматизации и без использования средств автоматизации. При обработке персональных данных без использования средств автоматизации Оператор руководствуется Положением об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации, утвержденной Постановлением Правительства Российской Федерации от 15 сентября 2008 г. N 687.<br>" +
              "2.5. При обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных». Оператор предоставляет услуги посредством размещения своего интернет-сайта на сайте //kenbu-kokoro.ru/. На сайте используется расширение HTTPS к протоколу HTTP в целях повышения безопасности и защиты информации.<br>" +
              "2.6. Оператор не раскрывает третьим лицам и не распространяет персональные данные без согласия субъекта персональных данных, кроме случаев, предусмотренных действующим законодательством РФ и настоящей Политикой.<br>" +
              "2.7. Оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Оператором требований Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса Российской Федерации.<br>" +
              "2.7.1.Соотношение указанного вреда и принимаемых Оператором мер, направленных на предупреждение, недопущение и/или устранение его последствий, определяется в соответствии с ФЗ РФ от 27.07.2006 N 152-ФЗ «О персональных данных».<br>" +
              "2.8. Условия обработки персональных данных Оператором:<br>" +
              "1) персональные данные обрабатываются Оператором после акцепта субъектом персональных данных оферты на оказание услуг.<br>" +
              "2) согласно п. 5 статьи 6 Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных» субъект персональных данных акцептует оферту по собственной инициативе и является стороной и выгодоприобретателем по заключенному им договору. Отдельное согласие на обработку персональных данных субъекта в таком случае не требуется.<br>" +
              "3) условия настоящей Политики доступны пользователям в открытом доступе, до момента акцепта оферты на оказание услуг.<br>" +
              "2.9. Хранение персональных данных пользователей осуществляется в форме, позволяющей определить субъекта персональных данных.<br>" +
              "2.10. Персональные данные подлежат уничтожению по достижении целей обработки. Удаление персональных данных осуществляется посредством удаления учетной записи, после чего такая запись не подлежит восстановлению.<br>" +
              "2.11. Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.<br>" +
              "4. ОТВЕТСТВЕННЫЙ ЗА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ<br>" +
              "4.1. Ответственным за обработку персональных данных является Оператор или его законный представитель.<br>" +
              "4.2. Ответственный за обработку персональных данных:<br>" +
              "4.2.1.осуществляет внутренний контроль за соблюдением законодательства Российской Федерации о персональных данных, в том числе требований к защите персональных данных;<br>" +
              "4.2.2.контролирует прием и обработку обращений и запросов субъектов персональных данных или их представителей;<br>" +
              "4.2.3. принимает меры для обнаружения фактов несанкционированного доступа к персональным данным;<br>" +
              "4.2.4.производит постоянный контроль за обеспечением уровня защищенности персональных данных;<br>" +
              "4.2.5. осуществляет внутренний контроль и (или) аудит соответствия обработки персональных данных Федеральному закону от 27.07.2006 N 152-ФЗ «О персональных данных» и принятым в соответствии с ним нормативными правовыми актами.<br>" +
              "5. ПОРЯДОК ОБЕСПЕЧЕНИЯ ОПЕРАТОРОМ ПРАВ СУБЪЕКТАПЕРСОНАЛЬНЫХ ДАННЫХ<br>" +
              "5.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных».<br>" +
              "5.2. Оператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».<br>" +
              "5.3. Полномочия представителя на представление интересов каждого субъекта персональных данных подтверждаются соответствующей доверенностью.<br>" +
              "5.4. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных в доступной форме без персональных данных, относящихся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных, в электронном виде. По требованию субъекта персональных данных они могут быть продублированы на бумаге.<br>" +
              "5.5. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных или его представителю при личном обращении либо при получении запроса субъекта персональных данных или его представителя. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись субъекта персональных данных или его представителя. При наличии технической возможности запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.<br>" +
              "5.6. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.<br>" +
              "5.7. Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту своего расположения в рабочее время.<br>"
        };
        openModal(Modal, props)
      },
      sendData() {
        let data = {
          product: this.selected_product.name,
          amount: this.totalCost,
          fio: this.name,
          email: this.email
        }
        let headers = {
          "Authorization": "Token f756c047576aa281b3d3ed9306f8c0e02e4a99dd",
        };
        axios
          .post('https://payment-back.kenbu-kokoro.ru/api/payment', data, {headers})
          .then(response => {
              window.location.href = response.data;
          })
          .catch(error => {
            console.log(error);
          })
      },
    }
}
</script>