<template>
  <div class = "modal-window">
    <h2>{{title}}</h2>
    <div v-html="text"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    text: String
  },
  name: "Modal"
}
</script>

<style scoped>
.modal-window{
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}
</style>